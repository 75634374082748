<div class="hero is-fullheight">
  <app-nav-menu [ngClass]="!isLoginPage ? 'show': 'hide'"></app-nav-menu>
  <section [ngClass]="!isLoginPage ? 'page-content' : ''">
    <router-outlet></router-outlet>
  </section>
  <footer class="footer has-text-centered is-flex-align-items-flex-end mt-auto" [ngClass]="!isLoginPage ? 'show': 'hide'">
    <small>
      <span>Copyright @2023</span>
      <br />
    </small>
    <a href="./assets/oferta.pdf" target="_blank">Договір публічної оферти</a>
  </footer>
</div>

<lib-toastify-toast-container
  [position]="'bottom-right'"
  [autoClose]="10000"
  [hideProgressBar]="true"
  [iconLibrary]="'font-awesome'"
></lib-toastify-toast-container>


<app-info-modal></app-info-modal>

