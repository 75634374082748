import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {HttpService} from './services/http.service';
import {UserService} from './services/user.service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(
    private router: Router,
    private authService: AuthService,
    private httpService: HttpService,
    private userService: UserService,
  ) {}
  public isLoginPage = false;

  async ngOnInit() {
    console.log('app init');

    this.router.events.subscribe((val) => { this.isLoginPage = this.router.url === '/login'; });
    if(!this.authService.checkAuth()) {
      await this.router.navigate(['/login'], {})
    } else {
      const {user} = await lastValueFrom(this.httpService.getUserProfile());
      this.userService.setUser(user);
    }
  }
}
