import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpService} from '../../services/http.service';
import {AuthService} from '../../services/auth.service';
import {finalize, lastValueFrom} from 'rxjs';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm = new FormGroup({
    licenseKey: new FormControl(''),
    login: new FormControl(''),
    password: new FormControl(''),
    rememberMe: new FormControl(false)
  })
  public isButtonLoading = false;

  constructor(private router: Router, private httpService: HttpService, private authService: AuthService, private userService: UserService) {
  }
  ngOnInit() {
    if(this.authService.checkAuth()) {
      this.router.navigate([''], {})
    }
  }

  public async onSubmit() {
    this.isButtonLoading = true;
    this.httpService.login(this.loginForm.value)
      .pipe(
        finalize(() => {this.isButtonLoading = false;})
      )
      .subscribe(async(res: any) => {
        if (res && res.token) {
          this.authService.setAuth(res.token)
          this.router.navigate([''], {})
          const {user} = await lastValueFrom(this.httpService.getUserProfile());
          this.userService.setUser(user);
        }
      })
  }

}
