import {Component, Input} from '@angular/core';
import {ICheck} from '../../interfaces/ICheck';
import {ToastService} from 'angular-toastify';

@Component({
  selector: 'app-orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.css']
})
export class OrdersHistoryComponent{
  constructor(private readonly toastService: ToastService) {
  }

  @Input()
  public history: ICheck[] = [];

  public async copyFileLink(id: string) {
    if(id) {
      const checkLink = `https://check.checkbox.ua/${id}`
      await navigator.clipboard.writeText(checkLink);
      this.toastService.success('Адреса чеку скопійована в буфер');
    }
  }

}
