import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors} from '@angular/forms';

import {round, cloneDeep, sumBy, find, first, assign} from 'lodash';
import {HttpService} from '../../services/http.service';
import {IOrder, IProduct} from '../../interfaces/IOrder';
import {ToastService} from 'angular-toastify';
import {finalize, lastValueFrom} from 'rxjs';
import {saveAs} from 'file-saver';
import {UserService} from '../../services/user.service';
import {OrderHistoryService} from '../../services/order-history.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnChanges, OnInit{
  constructor(
    private readonly httpService: HttpService,
    private readonly toastService: ToastService,
    private readonly userService: UserService,
    private readonly orderHistoryService: OrderHistoryService,
  ) {}
  @Input()
  public order: IOrder | null = null;

  @Input()
  public orderId: string | null = null;

  public isButtonLoading = false;

  public currency: string = 'грн';

  public orderTotalPriceWithoutDiscount: number = 0

  public productsBackup: IProduct[] = [];

  public discount: number = 0

  public checkLink: string | null = null;

  public cashlessLastThreeValues: [] = this.getLastThreeValuesFromLocalStorage();

  public isAllPayedCashless = false;

  public orderForm = new FormGroup({
    amountReceived: new FormControl(0, {nonNullable: true, validators: [this.orderAmountsValidator], updateOn: 'blur'}),
    cash: new FormControl(0, {nonNullable: true, validators: [this.orderAmountsValidator], updateOn: 'blur'}),
    cashless: new FormControl(0, {nonNullable: true, validators: [this.orderAmountsValidator], updateOn: 'blur'}),
  }, { updateOn: "submit" });

  ngOnInit() {
    this.addFormSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['order'].currentValue && changes['order'].currentValue !== changes['order'].previousValue) {
      this.reset();
    }

    if (this.order) {
      this.discount = this.order?.discount || 0;
      this.orderTotalPriceWithoutDiscount = (this.order?.orderTotalPrice + this.discount) || 0
      this.orderForm?.get('amountReceived')?.setValue(this.order?.orderTotalPrice, {emitEvent: false});
      this.productsBackup = cloneDeep(this.order?.products);
    }
  }

  public async createCheck() {
    this.isButtonLoading = true;
    await this.httpService.createCheck({
      products: this.order?.products,
      discount: this.discount,
      orderIds: this.orderId,
      ...this.orderForm.getRawValue(),
    }).pipe(
      finalize(() => {this.isButtonLoading = false;})
    ).subscribe(async (data) => {
      this.checkLink = `https://check.checkbox.ua/${data?.id}`;
      await this.copyFileLink()
      if(!this.isAllPayedCashless && this.orderForm.getRawValue().cashless > 0) {
        this.storeInLocalStorage(this.orderForm.getRawValue().cashless);
      }
      this.orderHistoryService.updateList({
        orderIds: this.orderId as string,
        checkId: data.id,
        amountReceived: this.orderForm.getRawValue().amountReceived,
        createdAt: Date.now()
      });
    }, async () => {
      const {user} = await lastValueFrom(this.httpService.getUserProfile());
      this.userService.setUser(user);
    });
  }

  public setCashlessValue(value: number) {
    this.orderForm?.get('cashless')?.setValue(value);
  }

  public async saveFile() {
    if(this.checkLink) {
      this.httpService.downloadFile(this.checkLink)
        .subscribe((blob) => {
          saveAs(blob, `Чек ${this.orderId}.pdf`);
        });
    }
  }

  public payAllWith(string: string) {
    const value = this.orderForm?.value?.amountReceived || 0
    if(string === 'cash') {
      this.orderForm?.get('cash')?.setValue(value, {emitEvent: false});
    } else {
      this.isAllPayedCashless = true;
      this.orderForm?.get('cashless')?.setValue(value, {emitEvent: false});
    }
  }

  public async copyFileLink() {
    if(this.checkLink) {
      await navigator.clipboard.writeText(this.checkLink);
      this.toastService.success('Адреса чеку скопійована в буфер');
    }
  }

  private addFormSubscriptions() {
    const cashControl = this.orderForm?.get('cash') as FormControl;
    const cashlessControl = this.orderForm?.get('cashless') as FormControl;
    const amountReceivedControl = this.orderForm?.get('amountReceived') as FormControl;
    amountReceivedControl.valueChanges.subscribe(this.amountReceivedRecalculate(amountReceivedControl));
    cashControl.valueChanges.subscribe((value) => {
      if(amountReceivedControl.value - value >= 0) {
        cashlessControl.setValue(round(amountReceivedControl.value - value, 2), { emitEvent: false });
      }
      cashControl.setValue(round(value, 2), { emitEvent: false });

    });
    cashlessControl.valueChanges.subscribe((value) => {
      if (value > 0) {
        if(amountReceivedControl.value - value >= 0) {
          cashControl.setValue(round(amountReceivedControl.value - value, 2), {emitEvent: false});
        }
        cashlessControl.setValue(round(value, 2), {emitEvent: false});
        this.isAllPayedCashless = false;
      }
    });
  }

  public onEnter($event: any) {
    $event.target.blur()
    this.orderForm.updateValueAndValidity();
  }

  private amountReceivedRecalculate(control: FormControl) {
    return (amountReceivedValue:number) => {
      control.setValue(round(amountReceivedValue, 2), {emitEvent: false});
      if (amountReceivedValue < this.orderTotalPriceWithoutDiscount) {
        // варіант 1
        this.discount = round(this.orderTotalPriceWithoutDiscount - amountReceivedValue, 2);
        if(this.order) {
          this.order.products = cloneDeep(this.productsBackup)
        }
        return;
      }
      // варіант 2
      this.discount = 0;
      if(this.order) {
        this.order.products = cloneDeep(this.productsBackup)
      }
      // пропорційно розбити переплату по товарах
      let diff = amountReceivedValue - this.orderTotalPriceWithoutDiscount;
      this.order?.products.forEach((product) => {
        this.calculateProductPrice(product, diff)
      });
      const sumTotalPrice = round(sumBy(this.order?.products, 'totalPrice'), 2);
      if (amountReceivedValue === sumTotalPrice) {
        return;
      }
      const totalPriceDiff = round(amountReceivedValue - sumTotalPrice, 2);
      // знайти продукт з quantity 1 і додати йому diff
      const targetSingleProduct = find(this.order?.products, { quantity: 1 });
      if (targetSingleProduct) {
        targetSingleProduct.price += totalPriceDiff;
        targetSingleProduct.totalPrice = round(targetSingleProduct.price * targetSingleProduct.quantity, 2);
        return;
      }
      // взяти перший продукт і розділити на 1 та х-1, додати diff до того, що 1
      const targetMultipleProduct = first(this.order?.products) as IProduct;
      targetMultipleProduct.quantity -= 1;
      targetMultipleProduct.totalPrice = round(targetMultipleProduct.price * targetMultipleProduct.quantity, 2);
      this.order?.products.push(assign({}, targetMultipleProduct, {
        quantity: 1,
        price: round(targetMultipleProduct.price + totalPriceDiff, 2),
        totalPrice: round(targetMultipleProduct.price + totalPriceDiff, 2)
      }));
    }
  }

  private calculateProductPrice(product: IProduct, diff: number) {
    const productDiff = round(product.price * 100 / this.orderTotalPriceWithoutDiscount * diff / 100, 2);
    product.price = round(product.price + productDiff, 2);
    product.totalPrice = round(product.price * product.quantity, 2);
  }

  private orderAmountsValidator(control: AbstractControl): ValidationErrors | null {
    const parent = control.root as FormGroup;
    const cash = parent.get('cash');
    const cashless = parent.get('cashless');
    const amountReceived = parent.get('amountReceived');
    if (round(cash?.value + cashless?.value, 2) !== amountReceived?.value) {
        cash?.setErrors({ orderAmountsInvalid: true });
        cashless?.setErrors({ orderAmountsInvalid: true });
        amountReceived?.setErrors({ orderAmountsInvalid: true });
    } else {
      cash?.setErrors(null);
      cashless?.setErrors(null);
      amountReceived?.setErrors(null);
    }
    return null;
  }
  private reset() {
    this.orderForm.get('cash')?.setValue(0, {emitEvent: false});
    this.orderForm.get('cashless')?.setValue(0, {emitEvent: false});
    this.checkLink = null;
    this.discount = this.order?.discount || 0;
  }

  public storeInLocalStorage(value: number) {
    const lastThreeValues = this.getLastThreeValuesFromLocalStorage();
    if(lastThreeValues.includes(value)) {
      return;
    }
    if (lastThreeValues.length === 3) {
      lastThreeValues.pop();
    }
    lastThreeValues.unshift(value);
    localStorage.setItem('lastThreeCashlessValues', JSON.stringify(lastThreeValues));
    this.cashlessLastThreeValues = lastThreeValues;
  }

  private getLastThreeValuesFromLocalStorage() {
    return JSON.parse(localStorage.getItem('lastThreeCashlessValues') || '[]');
  }
}
