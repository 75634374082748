<div class="container">
  <ng-container *ngIf="history && history.length">
    <h1 class="title is-5 mb-2">Чеки за сьогодні:</h1>
    <div class="list" >
      <div class="list-item" *ngFor="let hItem of history; let i = index" [attr.data-index]="i">
        <div class="list-item-content">
          <div class="list-item-title">#{{hItem.orderIds}}</div>
          <div class="list-item-description">
            <div>Час створення: {{hItem.createdAt | date:'HH:mm:ss'}}</div>
            <div>Сума: {{hItem.amountReceived}} грн</div>
          </div>
        </div>

        <div class="list-item-controls">
          <div class="buttons is-right">
            <button class="button is-white" title="Копіювати посилання на файл" (click)="copyFileLink(hItem.checkId)">
            <span class="icon">
              <i class="fa-solid fa-copy"></i>
            </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <p *ngIf="!history || !history.length">
    Сьогодні Ви ще не створювали чеків.
  </p>
</div>
