import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit{

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly userService: UserService
  ) { }

  public userBalance = 0;

  public registerStatusInfo: {[prop:string]: {translation: string, class: string}} =  {
    CREATED: {
      translation: 'Cтворена',
      class: 'has-text-info'
    },
    OPENED: {
      translation: 'Відкрита',
      class: 'has-text-success'
    },
    CLOSING: {
      translation: 'Закривається',
      class: 'has-text-warning'
    },
    CLOSED: {
      translation: 'Закрита',
      class: 'has-text-danger'
    },
  }
  public registerStatus: {translation: string, class: string} = this.registerStatusInfo['CREATED'];

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.userBalance = user.balance
      this.registerStatus = this.registerStatusInfo[user.registerStatus];
    });
  }

  public logout() {
    if(confirm('Ви впевнені що хочете вийти?')) {
      this.authService.removeAuth();
      this.router.navigate(['/login'], {});
    }
  }

  public gotoSettings() {
      this.router.navigate(['/settings'], {});
  }

  public gotoHome() {
    this.router.navigate([''], {});
  }
  public gotoTelegram() {
    window.open("https://t.me/prostochecksupport", "_blank");
  }
}
