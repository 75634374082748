import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {lastValueFrom} from 'rxjs';
import {IUpdateProfilePayload} from '../../interfaces/IUser';
import {isNil, omitBy} from 'lodash';
import {ToastService} from 'angular-toastify';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit{
  constructor(
    private readonly httpService: HttpService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalService,
    ) {
  }
  @ViewChild('paymentForm') paymentFormElement: any;
  @ViewChild('paymentModal') paymentModalElement: any;
  public coinsControl = new FormControl(50,{validators: [Validators.max(1000), Validators.min(1), Validators.pattern("^[0-9]*$")]});
  public paymentOptions = {} as any;

  public userProfilePlaceholder: IUpdateProfilePayload = {
    promToken: "",
    horoshopUrl: "",
    horoshopLogin: "",
    horoshopPassword: "",
    checkboxLogin: "",
    licenseKey: "",
  } as IUpdateProfilePayload;

  public userProfile: FormGroup = new FormGroup({
    promToken: new FormControl(''),
    horoshopUrl: new FormControl(''),
    horoshopLogin: new FormControl(''),
    horoshopPassword: new FormControl(''),
  });

  async ngOnInit() {
    //TODO: Update modal solution
    this.modalService.initEvents()
    const placeholder = await lastValueFrom(this.httpService.getUserProfilePlaceholders());
    this.userProfilePlaceholder = {...this.userProfilePlaceholder, ...omitBy(placeholder, isNil)};
  }

  public async buyCoins() {
    if(this.coinsControl.value) {
      const {payment} = await lastValueFrom(await this.httpService.topUpUserBalance(this.coinsControl.value));
      this.paymentOptions = payment;
      // setTimeout(() => {
      //   this.paymentFormElement.nativeElement.submit();
      // }, 0)
    }
  }


  public async updateUserProfile() {
    if(this.userProfile.valid) {
      const placeholder = await lastValueFrom(this.httpService.updateUserProfile(this.userProfile.getRawValue()));
      this.userProfilePlaceholder = {...this.userProfilePlaceholder, ...omitBy(placeholder, isNil)};

      this.toastService.success('Налаштування збережено');
      this.userProfile.reset()
    }
  }
}
