import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {IOrder} from '../../interfaces/IOrder';
import {UserService} from '../../services/user.service';
import {catchError, finalize, lastValueFrom} from 'rxjs';
import {ICheck} from '../../interfaces/ICheck';
import {OrderHistoryService} from '../../services/order-history.service';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{
  constructor(
    private userService: UserService,
    private orderHistoryService: OrderHistoryService,
    private readonly httpService: HttpService,
    private readonly modalService: ModalService,
  ) {}

  public orderId = '';
  public orderIdBackup: string | null = null;
  public order: IOrder | null = null;
  public isButtonLoading = false;
  public ordersHistory: ICheck[] = [];

  async ngOnInit() {
    const {list} = await lastValueFrom(this.httpService.getHistory());
    this.orderHistoryService.setList(list);

    this.orderHistoryService.getList()
      .subscribe((list) => {
        this.ordersHistory = list;
      })

    //TODO: Update modal solution
    this.modalService.initEvents()
  }

  public getOrder() {
    this.isButtonLoading = true;
    return this.httpService.getOrder(this.orderId)
      .pipe(
        finalize(() => {this.isButtonLoading = false;}),
        catchError((err) => {
          this.order = null;
          return err;
        })
      )
      .subscribe((res: any) => {
        this.orderIdBackup = this.orderId;
        this.order = res?.order;
      });
  }

}
