<nav class="navbar has-fixed-size" role="navigation" aria-label="main navigation">
  <div class="navbar-start is-align-items-center">
    <a class="navbar-item" href="/">
      <img src="./assets/logo-300.jpg" alt="logo">
    </a>
  </div>
  <div class="navbar-end">
    <div class="navbar-item">
      Робоча зміна: &nbsp;
      <span [ngClass]="registerStatus && registerStatus.class">
        {{registerStatus && registerStatus.translation}}
      </span>
    </div>
    <div class="navbar-item">
      Баланс каси: {{userBalance}} монет
    </div>
    <div class="navbar-item">
      <button class="button is-white" title="Головна сторінка" (click)="gotoHome()">
          <span class="icon is-large">
            <i class="fa-solid fa-house fa-xl has-text-grey"></i>
          </span>
      </button>
    </div>
    <div class="navbar-item">
      <button class="button is-white modal-trigger" title="Як це працює?" data-target="info-modal">
          <span class="icon is-large">
            <i class="fa-solid fa-xl fa-circle-question has-text-grey"></i>
          </span>
      </button>
    </div>
    <div class="navbar-item">
      <button class="button is-white" title="Налаштування" (click)="gotoSettings()">
          <span class="icon is-large">
            <i class="fa-solid fa-gear fa-xl has-text-grey"></i>
          </span>
      </button>
    </div>
    <div class="navbar-item">
      <button class="button is-white" title="Telegram" (click)="gotoTelegram()">
          <span class="icon is-large">
            <i class="fa-brands fa-telegram fa-xl has-text-grey"></i>
          </span>
      </button>
    </div>
    <div class="navbar-item">
      <button (click)="logout()" title="Вихід" class="button is-white">
          <span class="icon is-large">
            <i class="fa-solid fa-xl fa-arrow-right-from-bracket has-text-grey"></i>
          </span>
      </button>
    </div>
  </div>
</nav>
