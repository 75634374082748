import {Injectable} from '@angular/core';

@Injectable()
export class AuthService {
  constructor() {
  }

  public getAuth(): string | null {
    return window.localStorage.getItem('promwizard_auth');
  }

  public checkAuth(): boolean {
    return !!window.localStorage.getItem('promwizard_auth');
  }

  public setAuth(value: string): void {
    window.localStorage.setItem('promwizard_auth', value);
  }

  public removeAuth(): void {
    window.localStorage.removeItem('promwizard_auth');
  }

}
