import {Injectable} from '@angular/core';
import {IUser} from '../interfaces/IUser';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserService {

  constructor() {
  }
  private user: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({} as IUser)

  public getUser() {
    return this.user;
  }

  public setUser(user: IUser) {
    this.user.next(user);
  }

  public updateUser(user: Partial<IUser>){
    const newUser = {...this.user.getValue(), ...user}
    this.user.next(newUser);
  }
}
