<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="columns is-centered content is-align-items-end is-justify-content-center">
      <div class="">
        <div class="column is-flex is-align-items-center is-flex-direction-column" style="color: #485fc7">
          <figure class="image" style="width: 250px">
            <img src="assets/logo-300.jpg" alt="logo">
          </figure>
          <div style="width: 400px; font-size: 15px">
            <p style="margin-bottom: 5px">Перед початком введення даних для входу <b>відкрийте зміну в Checkbox</b>!</p>
          </div>
          <div class="box login-form">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="field">
                <label class="label"><span>Ключ ліцензії каси</span>&nbsp;<span class="has-tooltip-arrow has-tooltipl-multiline" data-tooltip="Кабінет Checkbox -> Каси -> скопіювати ключ ліцензії потрібної каси&#10;https://my.checkbox.ua/dashboard/cashregisters"><i class="fa-solid fa-circle-question"></i></span></label>
                <div class="control">
                  <input class="input" type="text" placeholder="Ключ ліцензії каси" formControlName="licenseKey">
                </div>
              </div>
              <div class="field">
                <label class="label"><span>Логін касира</span>&nbsp;<span class="has-tooltip-arrow has-tooltipl-multiline" data-tooltip="Кабінет Checkbox -> Касири -> скопіювати логін касира&#10;https://my.checkbox.ua/dashboard/employees"><i class="fa-solid fa-circle-question"></i></span></label>
                <div class="control">
                  <input class="input" type="text" placeholder="Логін касира" formControlName="login">
                </div>
              </div>
              <div class="field">
                <label class="label"><span>Пароль касира</span>&nbsp;<span class="has-tooltip-arrow has-tooltipl-multiline" data-tooltip="Кабінет Checkbox -> Касири -> натиснути правіше логіна касира ->&#10;Редагувати -> скопіювати або задати новий пароль касира&#10;https://my.checkbox.ua/dashboard/employees"><i class="fa-solid fa-circle-question"></i></span></label>
                <div class="control">
                  <input class="input" type="password" placeholder="Пароль касира" formControlName="password">
                </div>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" formControlName="rememberMe">
                  Запам'ятати мене
                </label>
              </div>
              <div class="control is-flex is-justify-content-end">
                <button class="button is-link" type="submit" [ngClass]="isButtonLoading ? 'is-loading' : ''">Увійти</button>
              </div>
            </form>
          </div>
          <div class="content" style="width: 400px; font-size: 15px;">
            <p><b>Prostocheck</b> - швидке створення чеків в сервісі РРО <b>Checkbox</b> по номенклатурі замовлень з ресурсів <b>Prom</b> та <b>Horoshop</b>.</p>
            <p>Сервіс не зберігає данні користувача. Всі необхідні для роботи сервісу дані зберігаються на локальному компʼютері.</p>
            <p>Для початку використання сервісу достатньо ввести необхідні дані для роботи, використовуючи підказки.</p>
            <p>Для повного ознайомлення та подальшої роботи надається можливість виписки 50 чеків.</p>
          </div>
          <div class="has-text-centered">
            <b>
              <u><a href="./assets/oferta.pdf" target="_blank" style="font-size: 15px;">Договір публічної оферти</a></u>
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
