import {NgModule} from '@angular/core';
import {LoginComponent} from './login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgClass} from '@angular/common';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    ReactiveFormsModule,
    NgClass
  ],
  bootstrap: [LoginComponent]
})
export class LoginModule{}
