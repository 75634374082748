<article class="panel pb-3" *ngIf="order">
  <form [formGroup]="orderForm">
    <p class="panel-heading">Замовлення #{{ orderId }}</p>
    <div class="order-body">
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
        <tr>
          <th>Назва</th>
          <th>Кількість</th>
          <th>Ціна</th>
          <th>Сума</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of order?.products; let i = index" [attr.data-index]="i">
          <td>{{ product?.name }}</td>
          <td>{{ product?.quantity }} шт</td>
          <td class="price">{{ product?.price }} {{ currency }}</td>
          <td class="price">{{ product?.totalPrice }} {{ currency }}</td>
        </tr>
        </tbody>
      </table>
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Сума замовлення</label>
        </div>
        <div class="field-body">
          <div class="field">
            <label>{{ orderTotalPriceWithoutDiscount }} {{ currency }}</label>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Виставлено замовнику</label>
        </div>
        <div class="field-body">
          <div class="field">
            <label>{{ order.orderTotalPrice }} {{ currency }}</label>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Сума, що виставлена</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control input-with-error">
              <input class="input" type="number" formControlName="amountReceived" (keyup.enter)="onEnter($event)" >
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Ви надали знижку</label>
        </div>
        <div class="field-body">
          <div class="field">
            <label>{{ discount }} {{ currency }}</label>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Безготівка</label>
        </div>
        <div class="field-body">
          <div class="field is-grouped has-addons">
            <div class="control input-with-error horizontal">
              <input class="input" type="number" formControlName="cashless" [ngClass]="orderForm.invalid ? 'is-danger' : ''" (keyup.enter)="onEnter($event)" >
            </div>
            <div class="control">
              <div class="button is-white" title="" (click)="payAllWith('card')">
              <span class="icon">
                <i class="fa-solid fa-credit-card"></i>
              </span>
              </div>
            </div>
            <div class="control">
              <span *ngFor="let value of cashlessLastThreeValues; let i = index" [attr.data-index]="i"  class="button is-ghost" (click)="setCashlessValue(value)">
                {{value}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Готівка</label>
        </div>
        <div class="field-body">
          <div class="field is-grouped has-addons">
            <div class="control input-with-error horizontal">
              <input class="input" type="number" formControlName="cash" [ngClass]="orderForm.invalid ? 'is-danger' : ''" (keyup.enter)="onEnter($event)" >
            </div>
            <div class="control">
              <div class="button is-white" (click)="payAllWith('cash')">
              <span class="icon">
                <i class="fa-solid fa-money-bill"></i>
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <!-- Left empty for spacing -->
        </div>
        <div class="field-body">
          <div class="field has-addons" *ngIf="orderForm.valid && !checkLink">
            <div class="control">
              <button class="button is-link"
                      type="button"
                      (click)="createCheck()"
                      [ngClass]="isButtonLoading ? 'is-loading' : ''">
                Видати чек
              </button>
            </div>
          </div>
          <div class="field is-grouped" *ngIf="checkLink">
            <div class="control is-align-items-center is-flex">
              <a class="button is-ghost is-small" [href]="checkLink" target="_blank">{{checkLink}}</a>
            </div>
            <div class="control">
              <div class="button is-white" title="Зберегти файл" (click)="saveFile()">
              <span class="icon">
                <i class="fa-solid fa-download"></i>
              </span>
              </div>
            </div>
            <div class="control">
              <div class="button is-white" title="Копіювати посилання на файл" (click)="copyFileLink()">
              <span class="icon">
                <i class="fa-solid fa-copy"></i>
              </span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  </form>
</article>
