<div class="container">
  <div class="columns">
    <div class="column">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-flex is-align-items-center">
              <label class="label mb-0">Ключ ліцензії каси:&nbsp;</label>
              <span class="tag is-info is-light is-medium">{{userProfilePlaceholder.licenseKey}}</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns">
            <div class="column is-flex is-align-items-center">
              <label class="label mb-0">Логін касира:&nbsp;</label>
              <span class="tag is-info is-light is-medium">{{userProfilePlaceholder.checkboxLogin}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="divider horizontal mb-6"></div>
      <div class="columns">
        <div class="column">
          <h3 class="subtitle is-4">Поповнення рахунку</h3>
          <div class="columns">
            <div class="column is-one-quarter is-flex is-align-items-center"><label class="label">Кількість монет</label></div>
            <div class="control column is-flex is-flex-direction-column is-half input-with-error">
              <label class="is-size-7">&nbsp;</label>
              <input class="input" type="number" [formControl]='coinsControl' [ngClass]="coinsControl.invalid ? 'is-danger':''">
              <label class="error">Допустима кількість від 1 до 1000</label>
            </div>
            <div class="column is-one-quarter is-flex is-align-items-center">
              <button class="button is-link modal-trigger" (click)="buyCoins()" [disabled]="coinsControl.invalid || !coinsControl.value" data-target="payment-modal">Оплатити</button>
            </div>
          </div>
        </div>
      </div>
      <div class="divider horizontal my-6"></div>
      <form [formGroup]="userProfile" (ngSubmit)="updateUserProfile()">
        <div class="columns">
          <div class="column">
            <h3 class="subtitle is-4">Налаштування Prom</h3>
            <div class="columns">
              <div class="column is-one-quarter is-flex is-align-items-center"><label class="label"><span>Prom токен</span>&nbsp;<span class="has-tooltip-arrow has-tooltip-right" data-tooltip="Кабінет Prom -> Налаштування -> Управління API-токенами ->&#10;Активні токени особистого доступу -> Дивитись"><i class="fa-solid fa-circle-question"></i></span></label></div>
              <div class="control column is-half">
                <input class="input" type="text" formControlName='promToken' [placeholder]="userProfilePlaceholder.promToken">
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <h3 class="subtitle is-4">Налаштування Horoshop</h3>
            <div class="columns">
              <div class="column is-one-quarter is-flex is-align-items-center"><label class="label">Url</label></div>
              <div class="control column is-half">
                <input class="input" type="text" formControlName="horoshopUrl" [placeholder]="userProfilePlaceholder.horoshopUrl">
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-quarter is-flex is-align-items-center"><label class="label">Логін</label></div>
              <div class="control column is-half">
                <input class="input" type="text" formControlName="horoshopLogin" [placeholder]="userProfilePlaceholder.horoshopLogin">
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-quarter is-flex is-align-items-center"><label class="label">Пароль</label></div>
              <div class="control column is-half">
                <input class="input" type="text" formControlName="horoshopPassword" [placeholder]="userProfilePlaceholder.horoshopPassword">
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-three-quarters is-justify-content-end is-flex">
            <button class="button is-link" type="submit" [disabled]="userProfile.invalid">Зберегти</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!--<form method="post" action="https://secure.wayforpay.com/pay" accept-charset="utf-8" #paymentForm>-->
<!--    <input type="hidden" name="amount" [(ngModel)]="paymentOptions['amount']">-->
<!--    <input type="hidden" name="clientAccountId" [(ngModel)]="paymentOptions['clientAccountId']">-->
<!--    <input type="hidden" name="currency" [(ngModel)]="paymentOptions['currency']">-->
<!--    <input type="hidden" name="merchantAccount" [(ngModel)]="paymentOptions['merchantAccount']">-->
<!--    <input type="hidden" name="merchantDomainName" [(ngModel)]="paymentOptions['merchantDomainName']">-->
<!--    <input type="hidden" name="merchantSignature" [(ngModel)]="paymentOptions['merchantSignature']">-->
<!--    <input type="hidden" name="orderDate" [(ngModel)]="paymentOptions['orderDate']">-->
<!--    <input type="hidden" name="orderReference" [(ngModel)]="paymentOptions['orderReference']">-->
<!--    <input type="hidden" name="productCount[]" [(ngModel)]="paymentOptions['productCount[]']">-->
<!--  <input type="hidden" name="productName[]" [(ngModel)]="paymentOptions['productName[]']">-->
<!--  <input type="hidden" name="productPrice[]" [(ngModel)]="paymentOptions['productPrice[]']">-->
<!--  <input type="hidden" name="returnUrl" [(ngModel)]="paymentOptions['returnUrl']">-->
<!--  <input type="hidden" name="serviceUrl" [(ngModel)]="paymentOptions['serviceUrl']">-->
<!--</form>-->

<app-payment-modal #paymentModal [paymentCredentials]="paymentOptions" ></app-payment-modal>



