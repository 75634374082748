import {Component, Input} from '@angular/core';
import {ToastService} from 'angular-toastify';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment.modal.component.html',
  styleUrls: ['./payment.modal.component.css']
})
export class PaymentModalComponent {

  constructor(
    private readonly toastService: ToastService,

  ) {
  }

  @Input()
  public paymentCredentials: any = {};

  async copyFileLink(text: string){
    await navigator.clipboard.writeText(text);
    this.toastService.success('Реквізит скопійований в буфер');
  }
}
