import {NgModule} from '@angular/core';
import {SettingComponent} from './setting.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgClass} from '@angular/common';
import {PaymentModalComponent} from '../../components/modals/payment.modal/payment.modal.component';

@NgModule({
  declarations: [SettingComponent, PaymentModalComponent],
  exports: [
    SettingComponent,
  ],
  imports: [
    FormsModule,
    NgClass,
    ReactiveFormsModule,

  ],
  bootstrap: [SettingComponent]
})
export class SettingModule {}
