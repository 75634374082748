import {Injectable, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class ModalService{
  constructor(@Inject(DOCUMENT) private document: Document) {

  }
  load() {
    this.document.addEventListener('DOMContentLoaded', this.initEvents.bind(this))
  }

  initEvents() {
    (this.document.querySelectorAll('.modal-trigger') || []).forEach(($trigger: Element) => {
      // @ts-ignore
      const modal = $trigger.dataset.target;
      const $target = this.document.getElementById(modal);

      $trigger.addEventListener('click', () => {
        this.openModal($target as HTMLElement);
      });
    });

    // Add a click event on various child elements to close the parent modal
    (this.document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
      const $target = $close.closest('.modal');

      $close.addEventListener('click', () => {
        this.closeModal($target as HTMLElement);
      });
    });

    // Add a keyboard event to close all modals
    this.document.addEventListener('keydown', (event) => {
      const e = event || window.event;

      if (e.keyCode === 27) { // Escape key
        this.closeAllModals();
      }
    });
  }

  openModal($el: Element | HTMLElement) {
    $el.classList.add('is-active');
  }

  closeModal($el: Element | HTMLElement) {
    $el.classList.remove('is-active');
  }

  closeAllModals() {
    (this.document.querySelectorAll('.modal') || []).forEach(($modal: Element) => {
      this.closeModal($modal);
    });
  }

}
