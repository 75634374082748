<div class="container">
  <div class="columns">
    <div class="column is-three-quarters">
      <div class="field">
        <label class="label"><span>Замовлення</span>&nbsp;<span class="has-tooltip-arrow has-tooltipl-multiline has-tooltip-right" data-tooltip="При необхідності виписати загальний чек на кілька замовлень ви можете записати їх через кому."><i class="fa-solid fa-circle-question"></i></span></label>
        <div class="control">
          <input class="input" type="text" placeholder="Введіть номер замовлення" [(ngModel)]="orderId">
        </div>
      </div>
      <div class="control mb-5">
        <button class="button is-link" (click)="getOrder()" [ngClass]="isButtonLoading ? 'is-loading' : ''">Підтвердити</button>
      </div>

      <app-order [order]="order" [orderId]="orderIdBackup"></app-order>
    </div>
    <div class="divider vertical"></div>
    <div class="column">
      <div class="info-button is-flex mb-5 modal-trigger" title="Як це працює?" data-target="info-modal">
        <img src="./assets/working.png" alt="working" height="128">
      </div>
      <app-orders-history [history]="ordersHistory"></app-orders-history>
    </div>
  </div>
</div>
