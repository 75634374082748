import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, from, map, Observable, of, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {IOrder} from '../interfaces/IOrder';
import {IUpdateProfilePayload, IUser} from '../interfaces/IUser';
import {ToastService} from 'angular-toastify';
import {UserService} from './user.service';
import {ICheck} from '../interfaces/ICheck';


interface ICheckboxResponse {
  response: any;
  user: IUser;
}

@Injectable()
export class HttpService {
  private readonly apiUrl = environment['base_api_url'];
  private httpOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private readonly toastService: ToastService,
    private readonly userService: UserService
  ) {}

  public login(params: any) {
    return this.http.post(`${this.apiUrl}auth/login`, params, {...this.httpOptions})
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }

  public getOrder(id: string):Observable<IOrder> {
    return this.http.get<IOrder>(`${this.apiUrl}marketplace/order/${id}`, {
      headers: this.withToken()
    })
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }

  public createCheck(body: any):Observable<any> {
    return this.http.post<ICheckboxResponse>(`${this.apiUrl}checkbox/create`, body, {
      headers: this.withToken()
    })
      .pipe(
        map(this.processResponse.bind(this)),
        catchError(this.handleError.bind(this))
      )
  }

  public getUserProfile():Observable<{user: IUser}> {
    return this.http.get<{user: IUser}>(`${this.apiUrl}users/me`, {
      headers: this.withToken()
    })
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }

  public getUserProfilePlaceholders():Observable<IUpdateProfilePayload> {
    return this.http.get<{virtual: IUpdateProfilePayload}>(`${this.apiUrl}users/profile`,{
      headers: this.withToken()
    })
      .pipe(
        map(({virtual}) => virtual),
        catchError(this.handleError.bind(this))
      )
  }

  public updateUserProfile(profile: IUpdateProfilePayload):Observable<IUpdateProfilePayload> {
    return this.http.put<{virtual: IUpdateProfilePayload}>(`${this.apiUrl}users/profile`, profile,{
      headers: this.withToken()
    })
      .pipe(
        map(({virtual}) => virtual),
        catchError(this.handleError.bind(this))
      )
  }

  public topUpUserBalance(count: number):Observable<any> {
    return this.http.post(`${this.apiUrl}orders/create-direct`, {count},{
      headers: this.withToken()
    })
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }

  public downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getHistory(): Observable<{list: ICheck[]}> {
    return this.http.get<any>(`${this.apiUrl}checkbox/list/today`, {
      headers: this.withToken()
    })
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }

  // public getHistory(): Observable<any> {
  //   return of({list: [
  //       {
  //         "_id": "6446f98aa515b5b13d20630c",
  //         "checkId": "8d789b2a-c32c-4e04-bba3-c909fcd6825a",
  //         "userLicenseKey": "testc2510beb80da37ea9dd9d782",
  //         "orderIds": "123",
  //         "amountReceived": 100,
  //         "createdAt": 1682373042556,
  //         "updatedAt": 1682373002556
  //       },
  //       {
  //         "_id": "6446f98aa515b5b13d20634c",
  //         "checkId": "8d789b2a-c32c-4e04-bba3-c909fcd6825a",
  //         "userLicenseKey": "testc2510beb80da37ea9dd9d782",
  //         "orderIds": "123",
  //         "amountReceived": 100,
  //         "createdAt": 1682373032556,
  //         "updatedAt": 1682373002556
  //       },
  //       {
  //         "_id": "6446f98aa515b5b13d20633c",
  //         "checkId": "8d789b2a-c32c-4e04-bba3-c909fcd6825a",
  //         "userLicenseKey": "testc2510beb80da37ea9dd9d782",
  //         "orderIds": "123",
  //         "amountReceived": 100,
  //         "createdAt": 1682373022556,
  //         "updatedAt": 1682373002556
  //       },
  //       {
  //         "_id": "6446f98aa515b5b13d20631c",
  //         "checkId": "8d789b2a-c32c-4e04-bba3-c909fcd6825a",
  //         "userLicenseKey": "testc2510beb80da37ea9dd9d782",
  //         "orderIds": "123",
  //         "amountReceived": 100,
  //         "createdAt": 1682373012556,
  //         "updatedAt": 1682373002556
  //       },
  //       {
  //         "_id": "6446f98aa515b5b13d20632c",
  //         "checkId": "8d789b2a-c32c-4e04-bba3-c909fcd6825a",
  //         "userLicenseKey": "testc2510beb80da37ea9dd9d782",
  //         "orderIds": "123",
  //         "amountReceived": 100,
  //         "createdAt": 1682373002556,
  //         "updatedAt": 1682373002556
  //       }
  //     ]})
  // }

  private withToken() {
    return {
      ...this.httpOptions.headers,
      Authorization: `Bearer ${this.authService.getAuth()}`
    }
  }

  private processResponse({response, user}: ICheckboxResponse) {
    this.userService.setUser(user);
    return response;
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 401) {
      this.authService.removeAuth();
      window.location.reload();
    }
    if (errorResponse.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', errorResponse.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${errorResponse.status}, body was: `, errorResponse.error);
    }
    this.toastService.error(errorResponse?.error?.message || errorResponse.message);
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
