<div id="payment-modal" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <header class="modal-card-head">
      <p class="modal-card-title">Реквізити для оплати</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
        <table class="table is-fullwidth">
          <caption class="is-size-4">Використовуйте приведені реквізити для формування платіжного доручення у вашому клієнт-банку</caption>
          <tbody>
            <tr>
              <td>Отримувач</td>
              <td>{{paymentCredentials.receiver}}</td>
              <td>
                <div class="button is-white" title="Копіювати" (click)="copyFileLink(paymentCredentials.receiver)">
                  <span class="icon">
                    <i class="fa-solid fa-copy"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>ЄДРПОУ отримувача</td>
              <td>{{paymentCredentials.edrpou}}</td>
              <td>
                <div class="button is-white" title="Копіювати" (click)="copyFileLink(paymentCredentials.edrpou)">
                  <span class="icon">
                    <i class="fa-solid fa-copy"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Розрахунковий рахунок</td>
              <td>{{paymentCredentials.iban}}</td>
              <td>
                <div class="button is-white" title="Копіювати" (click)="copyFileLink(paymentCredentials.iban)">
                  <span class="icon">
                    <i class="fa-solid fa-copy"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Сума</td>
              <td>{{paymentCredentials.amount}}</td>
              <td>
                <div class="button is-white" title="Копіювати" (click)="copyFileLink(paymentCredentials.amount)">
                  <span class="icon">
                    <i class="fa-solid fa-copy"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Призначення платежу</td>
              <td>{{paymentCredentials.purpose}}</td>
              <td>
                <div class="button is-white" title="Копіювати" (click)="copyFileLink(paymentCredentials.purpose)">
                  <span class="icon">
                    <i class="fa-solid fa-copy"></i>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </section>
  </div>
</div>
