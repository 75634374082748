import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {DatePipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrderComponent} from '../../components/order/order.component';
import {OrdersHistoryComponent} from '../../components/orders-history/orders-history.component';

@NgModule({
  declarations: [HomeComponent, OrderComponent, OrdersHistoryComponent],
  exports: [
    HomeComponent,
  ],
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    DatePipe
  ],
  providers: [],
  bootstrap: [HomeComponent]
})
export class HomeModule {}
