import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ToastService, AngularToastifyModule } from 'angular-toastify';

import {HomeModule} from './pages/home/home.module';
import {LoginModule} from './pages/login/login.module';

import { NavMenuComponent } from './components/nav-menu/nav-menu.component';

import {AuthService} from './services/auth.service';
import {HttpService} from './services/http.service';
import {SettingModule} from './pages/setting/setting.module';
import {UserService} from './services/user.service';
import {OrderHistoryService} from './services/order-history.service';
import {ModalService} from './services/modal.service';
import {InfoModalComponent} from './components/modals/info.modal/info.modal.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    InfoModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HomeModule,
    LoginModule,
    SettingModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularToastifyModule
  ],
  providers: [AuthService, HttpService, UserService, ToastService, OrderHistoryService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: ModalService) => () => ds.load(),
      deps: [ModalService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
