import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ICheck} from '../interfaces/ICheck';
import {IUser} from '../interfaces/IUser';

@Injectable()
export class OrderHistoryService {

  constructor() {
  }
  private list: BehaviorSubject<ICheck[]> = new BehaviorSubject<ICheck[]>([] as ICheck[])

  public getList() {
    return this.list;
  }

  public setList(list: ICheck[]) {
    this.list.next(list);
  }
  public updateList(item: ICheck){
    const newList = [item, ...this.list.getValue()]
    this.list.next(newList);
  }
}
