import { Component } from '@angular/core';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info.modal.component.html',
  styleUrls: ['./info.modal.component.css']
})
export class InfoModalComponent {

}
