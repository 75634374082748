<div id="info-modal" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <header class="modal-card-head">
      <p class="modal-card-title">Як це працює?</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <h4>
          Для початку роботи необхідно увійти в сервіс <a href="https://checkbox.ua/" target="_blank">checkbox.ua</a> і відкрити зміну.
        </h4>
        <p>
          Сервіс <b>Prostocheck</b> спрощує роботу та скорочує час виписки чека для замовлень з інтернет-магазинів на Prom і Horoshop. Якщо клієнт зробив кілька замовлень, можливо навіть на різних платформах у ваших магазинах, достатньо просто перерахувати номери замовлень через кому. <b>Prostocheck</b> зчитає номенклатуру по всіх замовленнях та передасть в <b>РРО Checkbox</b>.
        </p>
        <ul>
          <li><b>"Сума замовлення"</b> - роздрібна сума замовлення на сайті.</li>
          <li><b>"Виставлено замовнику"</b> - сума з урахуванням знижки, зробленої на сайті.</li>
          <li><b>"Сума, яка виставлена"</b> - дозволяє вам внести реальну суму чека, не редагуючи замовлення. Якщо ви надали знижку клієнту і не бажаєте, або не маєте можливості її внести в замовлення на сайті - вказуйте кінцеву суму чека в це поле. Зроблена знижка відобразиться в чеку. Інший приклад. Замовник бажає оплатити замовлення з доставкою і вам необхідно додати вартість доставки в чек, не виділяючи доставку окремою послугою. Без проблем. Ви можете вказати суму більшу вартості замовлення і <b>Prostocheck</b> пропорційно збільшить вартість товарів в чеку на необхідну суму.</li>
        </ul>
        <p>
          Якщо ви надали знижку клієнту і не бажаєте або не маєте можливості її внести в замовлення на сайті - вказуйте кінцеву суму чека в це поле. Зроблена знижка відобразиться в чеку. Інший приклад. Замовник бажає оплатити замовлення з доставкою і вам необхідно додати вартість доставки в чек, не виділяючи доставку окремою послугою. Без проблем. Ви можете вказати суму більшу вартості замовлення, і <b>Prostocheck</b> пропорційно збільшить вартість товарів в чеку на необхідну суму.
        </p>
        <p>
          <b>"Безготівка", "Готівка"</b> - поля для розділення оплат за замовленням. Наприклад, клієнт зробив передоплату та попросив накладений платіж на решту суми. Вносимо передоплату в поле <b>"Безготівка"</b>, і при переході в поле <b>"Готівка"</b> там автоматично з'явиться решта суми. Можливо, ви практикуєте продажі з постійною мінімальною передоплатою. Внесені вами передоплати будуть відображатися праворуч від поля <b>"Безготівка"</b> для миттєвого внесення в один клік.
        </p>
        <p>
          <b>Prostocheck</b> не зберігає жодних введених даних. Всі введені дані зберігаються на вашому пристрої. Для перегляду архіву чеків або операцій з чеками використовуйте кабінет <b>Checkbox</b>.
        </p>
        <p>
          Для першого знайомства з сервісом ви можете ввести дані тестової каси з вашого аккаунту <b>Checkbox</b>.
        </p>
      </div>
    </section>
  </div>
</div>
